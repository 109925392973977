
import { defineComponent, reactive, toRefs, ref, watch } from 'vue';
import ErrorDisplay from '@/components/shared/ErrorDisplay.vue';
import { login } from '@/services/aws-cognito.service';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';

// import store from '@/store/index';

export default defineComponent({
  components: {
    ErrorDisplay,
    EyeIcon,
    EyeOffIcon,
  },
  setup() {
    const state = reactive({
      loginForm: { email: '', password: '' },
    });
    const checked = ref(false);
    const isDisabled = ref(true);
    const isError = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('');
    const passwordType = ref('password');

    watch([state.loginForm], () => {
      const regexEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      regexEmail.test(String(state.loginForm.email).toLowerCase()) &&
      state.loginForm.password.length >= 8
        ? (isDisabled.value = false)
        : (isDisabled.value = true);
    });

    const handleLogin = async () => {
      isLoading.value = true;
      isError.value = false;
      login(state.loginForm.email, state.loginForm.password, (err, result) => {
        isLoading.value = false;
        if (err) {
          isError.value = true;
          errorMessage.value = err;
        } else {
          let accessToken = result.getAccessToken().getJwtToken();
          // if (checked.value) {
          let idToken = result.idToken.jwtToken;
          let refreshToken = result.refreshToken.token;
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('idToken', idToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('username', state.loginForm.email);
          // }
          location.replace('/');
        }
      });
    };
    return {
      handleLogin,
      checked,
      isError,
      errorMessage,
      isDisabled,
      isLoading,
      passwordType,
      ...toRefs(state),
    };
  },
});
